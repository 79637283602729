<template>
  <div>
  	<!--Amazon-->
    <span v-if="user && user.interview && user.interview.amazon">
      <span v-if="user && user.interview && user.interview.amazon && user.interview.amazon == 'Yes'">
        <i class="fa-solid fa-square mr-1" v-tooltip="'Worked at Amazon'" style="color:#ef4b46" ></i>
      </span>
      <span v-else-if="user && user.interview && user.interview.amazon && user.interview.amazon == 'No'">
        <i class="fa-solid fa-square mr-1" v-tooltip="'Never Worked at Amazon'" style="color:#00C897"></i>
      </span>
    </span>
    <!-- <span v-else>
      <i class="fa-solid fa-square mr-1" v-tooltip="`No Amazon Answer`" style="opacity:50%;"></i>
    </span> -->

    <!--Background Issues-->
    <span v-if="user && user.interview && user.interview.background && user.interview.background == 'No' ">
      <i class="fa-solid fa-square mr-1" v-tooltip="`No background issues`" style="color:#00C897"></i>
    </span>
    <span v-else-if="user && user.interview && user.interview.background && user.interview.background == 'Yes' && user.interview.explain">
      <i class="fa-solid fa-square mr-1" style="color:#ef4b46" v-tooltip="`Background issues ` + user.interview.explain"></i>
    </span>
    <span v-else>
      <i class="fa-solid fa-square mr-1" v-tooltip="`No background answer`" style="opacity:50%;"></i>
    </span>

    <!--Forty-->
    <span v-if="user && user.interview && user.interview.forty && user.interview.forty == 'Yes' ">
      <i class="fa-solid fa-square mr-1" v-tooltip="`Can Lift 40lbs`" style="color:#00C897"></i>
    </span>
    <span v-else-if="user && user.interview && user.interview.forty && user.interview.forty == 'No' ">
      <i class="fa-solid fa-square mr-1" style="color:#ef4b46" v-tooltip="`Cannot Lift 40lbs`"></i>
    </span>
    <span v-else>
      <i class="fa-solid fa-square mr-1" v-tooltip="`No lifting answer`" style="opacity:50%;"></i>
    </span>

    <!--Legal-->
    <span v-if="user && user.interview && user.interview.legal && user.interview.legal == 'Yes' ">
      <i class="fa-solid fa-square mr-1" v-tooltip="`Legal Worker`" style="color:#00C897"></i>
    </span>
    <span v-else-if="user && user.interview && user.interview.legal && user.interview.legal == 'No' ">
      <i class="fa-solid fa-square mr-1" style="color:#ef4b46" v-tooltip="`Not a Legal Worker`"></i>
    </span>
    <span v-else>
      <i class="fa-solid fa-square mr-1" v-tooltip="`No legality answer`" style="opacity:50%;"></i>
    </span>

    <!--Nights-->
    <span v-if="user && user.interview && user.interview.passDrugTest && user.interview.nightsWeekends == 'Yes' ">
      <i class="fa-solid fa-square mr-1" v-tooltip="`Can Work Nights and Weekends`" style="color:#00C897"></i>
    </span>
    <span v-else-if="user && user.interview && user.interview.passDrugTest && user.interview.nightsWeekends == 'No' ">
      <i class="fa-solid fa-square mr-1" style="color:#ef4b46" v-tooltip="`Cannot Work Nights and Weekends`"></i>
    </span>
    <span v-else>
      <i class="fa-solid fa-square mr-1" v-tooltip="`No Nights and Weekends answer`" style="opacity:50%;"></i>
    </span>

    <!--Drug-->
    <span v-if="user && user.interview && user.interview.passDrugTest && user.interview.passDrugTest == 'Yes' ">
      <i class="fa-solid fa-square mr-1" v-tooltip="`Will Pass Drug Test`" style="color:#00C897"></i>
    </span>
    <span v-else-if="user && user.interview && user.interview.passDrugTest && user.interview.passDrugTest == 'No' ">
      <i class="fa-solid fa-square mr-1" style="color:#ef4b46" v-tooltip="`May Not Pass Drug Test`"></i>
    </span>
    <span v-else>
      <i class="fa-solid fa-square mr-1" v-tooltip="`No Drug Test answer`" style="opacity:50%;"></i>
    </span>

    <!--Standing-->
    <span v-if="user && user.interview && user.interview.standing && user.interview.standing == 'Yes' ">
      <i class="fa-solid fa-square mr-1" v-tooltip="`Comfortable Standing and Walking`" style="color:#00C897"></i>
    </span>
    <span v-else-if="user && user.interview && user.interview.standing && user.interview.standing == 'No'">
      <i class="fa-solid fa-square mr-1" style="color:#ef4b46" v-tooltip="`Not Comfortable Standing and Walking`"></i>
    </span>
    <span v-else>
      <i class="fa-solid fa-square mr-1" v-tooltip="`No Standing and Walking answer`" style="opacity:50%;"></i>
    </span>

    <!--transportation-->
    <span v-if="user && user.interview && user.interview.transportation && user.interview.transportation == 'Yes' ">
      <i class="fa-solid fa-square mr-1" v-tooltip="`Has Reliable Transportation`" style="color:#00C897"></i>
    </span>
    <span v-else-if="user && user.interview && user.interview.transportation && user.interview.transportation == 'No' ">
      <i class="fa-solid fa-square mr-1" style="color:#ef4b46" v-tooltip="`No Reliable Transportation`"></i>
    </span>
    <span v-else>
      <i class="fa-solid fa-square mr-1" v-tooltip="`No Reliable Transportation answer`" style="opacity:50%;"></i>
    </span>

    <!--Shift Preference-->
    <span v-if="user && user.interview && user.interview.shifts">
      <span v-if="user && user.interview && user.interview.shifts">
        <i class="fa-solid fa-square mr-1" v-tooltip="`Shift Preference: ` + user.interview.shifts" style="color:#00C897"></i>
      </span>
      <span v-else>
        <i class="fa-solid fa-square mr-1" v-tooltip="`No Shift Preference answer`" style="opacity:50%;"></i>
      </span>
    </span>

    <!--Start-->
    <span v-if="user && user.interview && user.interview.when">
      <span v-if="user && user.interview && user.interview.when">
        <i class="fa-solid fa-square mr-1" v-tooltip="`Willing to start: ` + user.interview.when" style="color:#00C897"></i>
      </span>
      <span v-else>
        <i class="fa-solid fa-square mr-1" v-tooltip="`No Start Date answer`" style="opacity:50%;"></i>
      </span>
    </span>

    <!--Shift Preference-->
    <span v-if="user && user.interview && user.interview.experience">
      
      <i class="fa-solid fa-square mr-1" v-tooltip="`Experience: ` + user.interview.experience" style="color:#00C897"></i>
    </span>
    <span v-if="user && user.interview && !user.interview.experience">
      <i class="fa-solid fa-square mr-1" v-tooltip="`No Experience Listed`" style="opacity:50%;"></i>
    </span>
    


  </div>
</template>

<script>
	export default {
		props: ['user'],
	}
</script>